import { styled, t } from "~src/designSystem/theme";
import {
  contentColorStorybookArgs,
  contentColorVariants,
  fontSizeStorybookArgs,
  fontSizeVariants,
} from "~src/designSystem/theme/variants";
import { castToStitchesStoryComponent } from "~src/storybook/utils";

export const Text = styled("span", contentColorVariants, fontSizeVariants, {
  // Reset
  fontStyle: "normal",
  textTransform: "none",
  textDecoration: "none",
  textIndent: "0px",
  letterSpacing: t.letterSpacings.sm,

  variants: {
    type: {
      light: {
        fontFamily: t.fonts.sans,
        fontWeight: "400",
      },
      regular: {
        fontFamily: t.fonts.sans,
        fontWeight: "500",
      },
      bold: {
        fontFamily: t.fonts.sans,
        fontWeight: "600",
      },
      display: {
        fontFamily: t.fonts.sans,
        fontWeight: "700",
      },
      code: {
        fontFamily: t.fonts.mono,
        fontWeight: "600",
      },
    },
    // TODO(md): Fill out & document.
    whitespace: {
      nowrap: {
        "white-space": "nowrap",
      },
    },
  },
  defaultVariants: {
    type: "regular",
  },
});

export const StorybookText = castToStitchesStoryComponent(Text);

export const StorybookTextArgs = {
  type: {
    description: "The text type. This affects the weight and font family.",
    control: { type: "select" },
    table: {
      category: "Text Variants",
      defaultValue: { summary: "regular" },
    },
  },
  ...fontSizeStorybookArgs,
  ...contentColorStorybookArgs,
  children: {
    description: "The text content to display.",
  },
};
